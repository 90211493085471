import { cx } from 'utils';
import styles from './Content.module.css';

export type ContentProps = {
  className?: string;
  center?: boolean;
};
export const Content: React.FCWithChildren<ContentProps> = ({ children, center, className }) => {
  return (
    <main className={cx(styles.root, { [styles.center]: center }, className)}>{children}</main>
  );
};
