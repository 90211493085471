import React from 'react';
import Head from 'next/head';

import { TITLE_BASE } from 'consts';

const MetaTitle: React.FCWithChildren<{ children?: string }> = ({ children = '' }) => {
  const title = children.trim();
  const suffix = ` - ${TITLE_BASE}`;
  const fullTitle = (() => {
    if (title === '') return TITLE_BASE;
    if (title.endsWith(suffix)) return title;
    return `${title}${suffix}`;
  })();
  return (
    <Head>
      <title key="title">{fullTitle}</title>
      <meta key="og:title" property="og:title" content={fullTitle} />
    </Head>
  );
};

export default MetaTitle;
