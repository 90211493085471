import React from 'react';
import ReactDOM from 'react-dom';

import Backdrop, { BackdropProps } from 'components/Backdrop';
import ScrollLock from 'components/ScrollLock';
import { MODAL_ROOT_ID } from 'consts';
import useEscape from 'hooks/useEscape';
import useRouteAway from 'hooks/useRouteAway';

const ROOT = typeof window !== 'undefined' ? document.getElementById(MODAL_ROOT_ID) : null;

export interface DialogProps {
  open: boolean;
  noPortal?: boolean;
  noBackdrop?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  backdropProps?: BackdropProps;
}

/**
 * Renders something on top of the site along with an optional backdrop and a scroll-lock
 * Does not handle z-index, but there is a suitable variable to be used alongside "--z-dialog"
 */
const Dialog: React.FCWithChildren<DialogProps> = ({
  children,
  onClose,
  noBackdrop = false,
  backdropProps = {},
  open,
  noPortal,
}) => {
  // render the item straight into the current DomPosition
  useRouteAway(() => onClose && onClose());
  useEscape(() => onClose && onClose());

  const content = (
    <>
      {!noBackdrop && <Backdrop {...backdropProps} open={open} onClick={onClose} />}
      {open && <ScrollLock />}
      {children}
    </>
  );

  if (noPortal) {
    return content;
  }

  // Render in using a portal in a separate react tree.
  if (ROOT) {
    return ReactDOM.createPortal(content, ROOT);
  }
  return null;
};
export default Dialog;
