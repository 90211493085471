import React from 'react';

import { cx } from 'utils';

interface SpacedProps {
  className?: string;
  component?: React.ElementType;
  children: React.ReactNode;
  spacing?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
}

const Spaced = React.forwardRef<HTMLElement, SpacedProps>(function Spaced(
  { children, component: Component = 'div', spacing = 1, className = '' },
  ref,
) {
  return (
    <Component ref={ref} className={cx(`spacing-top-${spacing}`, className)}>
      {children}
    </Component>
  );
});

export default Spaced;
