import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

import styles from './Backdrop.module.css';

export type BackdropProps = {
  className?: string;
  open?: boolean;
  onClick?: () => void;
};

const TRANSITION_KEY = 'backdrop-transition';

/**
 * Backdrop
 * Adds a semi-transparent overlay
 */

const Backdrop: React.FCWithChildren<BackdropProps> = ({
  open = true,
  onClick,
  className,
  ...rest
}) => {
  return (
    <CSSTransition
      unmountOnExit
      mountOnEnter
      in={open}
      timeout={125}
      classNames={{
        enterDone: styles.visible,
        enterActive: styles.visible,
        exitDone: styles.exitDone,
      }}
    >
      <div
        key={TRANSITION_KEY}
        className={cx(styles.root, className)}
        onClick={onClick}
        {...rest}
      />
    </CSSTransition>
  );
};

export default Backdrop;
