import React from 'react';

import Block, { BlockProps } from 'components/Block';
import useFocusTrap from 'hooks/useFocusTrap';
import { cx } from 'utils';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Base.module.css';

export interface ModalProps extends BlockProps {
  className?: string;
  position?: 'left' | 'right' | 'middle' | 'bottom';
  onClose?: () => void;
}

export const Base: React.FCWithChildren<ModalProps> = ({
  children,
  className,
  onClose,
  position = 'middle',
  padding = 'none',
  background = 'white',
  ...rest
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  useFocusTrap<HTMLDivElement>(ref.current);

  return (
    <Block
      padding={padding}
      background={background}
      className={cx(styles.position, styles[position])}
    >
      <div ref={ref} {...rest} className={cx(styles.root, className)}>
        {typeof onClose === 'function' && (
          <button type="button" className={styles.btnClose} aria-label="close" onClick={onClose} />
        )}
        {children}
      </div>
    </Block>
  );
};

Base.displayName = 'Base';
