import React from 'react';

import useIntersection from 'hooks/useIntersection';
import { cx } from 'utils';
import styles from './Image.module.css';

export type AspectRatio =
  | 'square'
  | 'wide' // seems unused
  | 'letterbox'
  | 'letterbox-portrait'
  | 'letterbox-category' // unused?
  | number;

function aspectToNum(aspect: AspectRatio): number {
  switch (aspect) {
    case 'square':
      return 1;
    case 'wide':
      return 16 / 9;
    case 'letterbox':
      return 4 / 3;
    case 'letterbox-portrait':
      return 3 / 4;
    case 'letterbox-category':
      return 32 / 37;
    default:
      return aspect;
  }
}

export interface ImageProps extends React.HTMLAttributes<HTMLImageElement> {
  src: string;
  srcSet?: string;
  alt: string;
  aspectRatio?: AspectRatio;
  className?: string;
}

// value in pixels for when to load offscreen images
const LOAD_OFFSET = 100;

const Image = ({ src, srcSet, alt, aspectRatio = 'square', className, ...rest }: ImageProps) => {
  const [isInView, setIsInView] = React.useState(false);
  const ref = React.useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = React.useState(false);

  useIntersection(
    ref,
    () => {
      setIsInView(true);
    },
    { rootMargin: `${LOAD_OFFSET}px` },
  );

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.root, className)}
        style={{ ['--aspect-ratio']: aspectToNum(aspectRatio) } as React.CSSProperties}
      >
        {isInView && (
          <img
            className={cx(styles.image, { [styles.loaded]: loaded })}
            onLoad={() => setLoaded(true)}
            alt={alt || ''} // technically spread, but linter complains otherwise, so let's use TS to enforce usage.
            src={src}
            srcSet={srcSet}
            {...rest}
          />
        )}
      </div>
    </>
  );
};

export default Image;
