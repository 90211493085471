import React from 'react';

import Image from 'components/Image';
import Typography from 'components/Typography';
import { cx } from 'utils';
import styles from './ExhibitionCard.module.css';

export type ExhibitionCardProps = {
  exhibition: {
    title: string;
    subtitle?: string;
    image: string;
    slug: string;
  };
  flip?: boolean;
};

/** A simple ExhibitionCard that can behave as a link or button  */
const ExhibitionCard: React.FCWithChildren<ExhibitionCardProps> = ({ exhibition, flip }) => {
  const { title, subtitle, image } = exhibition;
  return (
    <div className={cx(styles.root, { [styles.flip]: flip })}>
      <Image src={image} alt={title} aspectRatio="letterbox" />
      <div className={styles.text}>
        <Typography variant="sub" block>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="sub" block color="faded">
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ExhibitionCard;
