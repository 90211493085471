import React from 'react';
import cx from 'classnames';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './Grid.module.css';

export interface GridProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  gap?: 'small' | 'regular' | 'big';
  columns?: 2 | 3 | 4 | 6;
  component?: React.ElementType;
}

/**
 * A simple grid-layout
 */

const Grid: React.FCWithChildren<GridProps> = ({
  className,
  columns = 2,
  gap = 'regular',
  children,
  ...rest
}) => {
  return (
    <div
      className={cx(
        styles.root,
        { [styles[`gap-${gap}`]]: gap, [styles[`columns-${columns}`]]: columns },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Grid;
