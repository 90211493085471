export { Base as Modal } from './Base';
export { Title as ModalTitle } from './Title';
export { Actions as ModalActions } from './Actions';
export { Content as ModalContent } from './Content';

/**
 * A set of Modal Component parts
 *  Example usage:
 *  
    <Modal>
        <ModalTitle>Titel</ModalTitle>
        <ModalActions>
            <Button color="black">Gå vidare</Button>
            <Button color="white">
            Avbryt
            </Button>
        </ModalActions>
    </Modal>
 */
export type { ContentProps as ModalContentProps } from './Content';
export type { TitleProps as ModalTitleProps } from './Title';
export type { ActionsProps as ModalActionsProps } from './Actions';
export type { ModalProps } from './Base';
