import Typography, { TypographyProps } from 'components/Typography';
import { cx } from 'utils';
import styles from './Title.module.css';

export interface TitleProps extends Pick<TypographyProps, 'align'> {
  className?: string;
}

export const Title: React.FCWithChildren<TitleProps> = ({ children, className, align }) => {
  return (
    <header className={cx(styles.title, className)}>
      <Typography block align={align} variant="heading">
        {children}
      </Typography>
    </header>
  );
};
