import React from 'react';
import cx from 'classnames';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './Container.module.css';

export interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  fullHeight?: boolean;
  className?: string;
  desktop?: boolean;
  variant?: 'full' | 'article';
  padding?: 'none' | 'regular';
  component?: React.ElementType;
  noNestedPadding?: boolean;
}

/**
 * Contained content, adding a max-width and vertical padding.
 * Should be used in conjunction with <Block/> to create layouts.
 * */
const Container: React.FCWithChildren<ContainerProps> = ({
  fullHeight = false,
  className,
  children,
  padding = 'regular',
  variant,
  noNestedPadding = false,
  ...rest
}) => {
  return (
    <div
      className={cx(
        styles.root,
        {
          [styles.full]: variant === 'full',
          [styles.article]: variant === 'article',
          [styles.fullHeight]: fullHeight,
          [styles[`padding-regular`]]: padding === 'regular',
          ['container']: padding === 'regular',
          [styles[`no-nested-padding`]]: noNestedPadding,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Container;
