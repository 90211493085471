import React from 'react';
import { useRouter } from 'next/router';

const useRouteAway = (handler: () => void) => {
  const { events } = useRouter();

  React.useEffect(() => {
    events.on('routeChangeStart', handler);

    return () => {
      events.off('routeChangeStart', handler);
    };
  }, [events, handler]);
};

export default useRouteAway;
