import useEventListener from './useEventListener';

const FOCUSABLE_SELECTOR =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

type InputElement =
  | HTMLButtonElement
  | HTMLLinkElement
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

export default function useFocusTrap<T extends HTMLElement>(element?: T | null) {
  const focusableContent: InputElement[] = Array.from(
    element?.querySelectorAll(FOCUSABLE_SELECTOR) || [],
  );

  const firstFocusableElement = focusableContent[0];
  const lastFocusableElement = focusableContent[focusableContent.length - 1];

  useEventListener('keydown', event => {
    const e = event as KeyboardEvent;

    if (element && e.key === 'Tab') {
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    }
  });

  firstFocusableElement?.focus();
}
