import { NodeTreeItem } from 'djedi-json';

import { DJEDI_IMAGE_TYPE } from 'consts/cms';

export default function exhibitionThumbnail(exhibition: NodeTreeItem): string | undefined {
  const children = exhibition.content.children;
  const imageNode = Array.isArray(children)
    ? children?.find(({ type }) => type === DJEDI_IMAGE_TYPE)
    : undefined;
  return exhibition?.content?.thumbnail?.image_960w ?? imageNode?.content.image.image_960w;
}
